import { Component, OnInit, ViewChild, Renderer2, ElementRef, Input, SimpleChanges, OnChanges} from '@angular/core';
import { PREMIUM_LIST, PROFESIONAL_LIST } from '../../users/list.data';
import { IPlanInfo } from 'src/app/interfaces/plan-info.interface';

@Component({
  selector: 'app-step-one-quoter',
  templateUrl: './step-one-quoter.component.html',
  styleUrls: ['./step-one-quoter.component.css']
})
export class StepOneQuoterComponent implements OnInit {

  constructor(private renderer: Renderer2) {
    this.PremiumList = PREMIUM_LIST;
    this.ProfesionalList = PROFESIONAL_LIST;
  }
  processingPayment: boolean = false;
  referredName: string = '';
  referred_id: string = '';
  stateName: string = '';

  selectedPlanOption: string = 'PREMIUM';
  selectedPaymentOption: string = 'MENSUAL';

  // INPUTS VARIABLES
  @Input() plan: IPlanInfo;
  // VIEW CHILD
  @ViewChild('list_features_plan') listFeaturesPlanElement!: ElementRef<HTMLUListElement>;
  // LISTS
  PremiumList: string[] = [];
  ProfesionalList: string[] = [];


  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Si el cambio se refiere a la propiedad 'plan'
    if (changes['plan'] && this.plan) {
      this.displayInfoPlan();
    }
  }

  async selectOption(planType: string): Promise<void> {
    this.plan.number_of_users = 1;

    if (planType === 'PREMIUM') {
      this.selectedPlanOption = 'PREMIUM';
      // this.changePriceByPlansSelected();
      this.displayInfoPlan();
    } else {
      this.selectedPlanOption = 'PROFESIONAL';
      // this.changePriceByPlansSelected();
      this.displayInfoPlan();
    }
  }

  async selectPaymentOption(paymentType: string) {
    this.plan.number_of_users = 1;

    if (paymentType === 'ANUAL') {
      this.selectedPaymentOption = 'ANUAL';
      // this.changePriceByPlansSelected();
    } else {
      this.selectedPaymentOption = 'MENSUAL';
      // this.changePriceByPlansSelected();
    }
  }

  private displayInfoPlan(): void {
    let stringDescription: string = "";

    if (this.plan?.plan_short_name == 'PROFESIONAL') {
      this.ProfesionalList.forEach(profesional => { stringDescription += `<li>${profesional}</li>` });
    }
    if (this.plan?.plan_short_name == 'PREMIUM') {
      this.PremiumList.forEach(premium => { stringDescription += `<li>${premium}</li>` });
    }

    this.renderer.setProperty(this.listFeaturesPlanElement.nativeElement, 'innerHTML', stringDescription);
  }
}
