import { IPlanInfo } from 'src/app/interfaces/plan-info.interface';

export const REGIMEN_LIST = [
  { "id": "601", "value": "General de Ley Personas Morales" },
  { "id": "603", "value": "Personas Morales con Fines no Lucrativos" },
  { "id": "605", "value": "Sueldos y Salarios e Ingresos Asimilados a Salarios" },
  { "id": "606", "value": "Arrendamiento" },
  { "id": "608", "value": "Demás ingresos" },
  { "id": "609", "value": "Consolidación" },
  { "id": "610", "value": "Residentes en el Extranjero sin Establecimiento Permanente en México" },
  { "id": "611", "value": "Ingresos por Dividendos (socios y accionistas)" },
  { "id": "612", "value": "Personas Físicas con Actividades Empresariales y Profesionales" },
  { "id": "614", "value": "Ingresos por intereses" },
  { "id": "616", "value": "Sin obligaciones fiscales" },
  { "id": "620", "value": "Sociedades Cooperativas de Producción que optan por diferir sus ingresos" },
  { "id": "621", "value": "Incorporación Fiscal" },
  { "id": "622", "value": "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras" },
  { "id": "623", "value": "Opcional para Grupos de Sociedades" },
  { "id": "624", "value": "Coordinados" },
  { "id": "628", "value": "Hidrocarburos" },
  { "id": "607", "value": "Régimen de Enajenación o Adquisición de Bienes" },
  { "id": "629", "value": "De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales" },
  { "id": "630", "value": "Enajenación de acciones en bolsa de valores" },
  { "id": "615", "value": "Régimen de los ingresos por obtención de premios" },
  { "id": "625", "value": "Régimen de Actividades Empresariales con ingreso a través de las plataformas tecnológicas" },
  { "id": "626", "value": "Régimen Simplificado de Confianza" }
];

export const LINE_OF_BUSINESS_LIST = [
  { "id": "CI", "value": "Contador Independiente" },
  { "id": "D", "value": "Despacho Contable" },
  { "id": "PYMENOCP", "value": "PyME" }
];

export const FUENTE_LIST = [
  { "id": "439", "value": "Facebook" },
  { "id": "441", "value": "Instagram" },
  { "id": "442", "value": "Correo" },
  { "id": "351", "value": "Recomendación" },
];

export const PROFESIONAL_LIST = [
  "RFCs Descarga automática de CFDIs desde el SAT",
  "Contabilidad y reportes",
  "Estados financieros",
  "Tesorería y conciliación",
  "Cálculo automático de impuestos",
  "Auditoría",
  "Cotizaciones",
  "Contabilidad electrónica",
  "Migración automática desde Contpaq",
  "APP Móvil para consulta y facturación",
  "Emisión de facturas y complementos (adquisición adicional de paquete de timbres)"
];

export const PIPEDRIVE_REFERENCE = [
  { "id": "3", "value": "Facebook" },
  { "id": "4", "value": "Instagram" },
  { "id": "12", "value": "Correo" },
  { "id": "1", "value": "Recomendación" },
]

export const PREMIUM_LIST = [
  "Todos los beneficios de Plan Pro",
  "Cálculo y timbrado de Nómina",
  "Facturación recurrente y cobranza",
  `Timbres ilimitados para:
      <ul>
        <li>Facturas</li>
        <li>Complementos</li>
        <li>Notas de crédito</li>
        <li>Nómina</li>
      </ul>`,
  `Tablero de documentos de todos los clientes, descarga automática de:
      <ul>
        <li>Constancia de RFC</li>
        <li>Opinión de cumplimiento</li>
        <li>Opinión de IMSS e INFONAVIT</li>
        <li>Comunicados del Buzón Tributario SAT</li>
        <li>Declaraciones</li>`,
  "White Label (Marca blanca, requiere un mínimo de usuarios)"
];

export const DISCOUNT_TABLE: { [key: number]: number } = {
  1: 0,
  2: 10,
  3: 20,
  4: 30,
  5: 40,
};

export const DISCOUNT_HEADER_TEXT: { [key: string]: string } = {
  'PREMIUM MENSUAL': '50% de descuento en tus primeros 3 meses. En pago anual 25% de descuento',
  'PREMIUM ANUAL': 'En pago anual 25% de descuento',
  'PROFESIONAL MENSUAL': '50% de descuento en tus primeros 3 meses. En pago anual 25% de descuento',
  'PROFESIONAL ANUAL': 'En pago anual 25% de descuento'
};

export const DISCOUNT_TEXT: { [key: string]: string } = {
  'PREMIUM MENSUAL': 'Promoción de Bienvenida: 50% de descuento en cada una de tus primeras 3 mensualidades. Promoción para cuentas nuevas. No aplica en recontrataciones.',
  'PREMIUM ANUAL': 'Promoción 25% de descuento en pago anual. Descuento aplica en el primer año de contratación.',
  'PROFESIONAL MENSUAL': 'Promoción de Bienvenida: 50% de descuento en cada una de tus primeras 3 mensualidades. Promoción para cuentas nuevas. No aplica en recontrataciones.',
  'PROFESIONAL ANUAL': 'Promoción 25% de descuento en pago anual. Descuento aplica en el primer año de contratación.'
};

export const REFERRER_TEXT: { [key: string]: string } = {
  'PREMIUM MENSUAL': 'DESCUENTO POR PROGRAMA DE REFERIDOS. Válido durante los 4 primeros meses de suscripción. Promoción para cuentas nuevas. No aplica en recontrataciones.',
  'PREMIUM ANUAL': 'DESCUENTO POR PROGRAMA DE REFERIDOS. Válido durante el 1er año de suscripción. Promoción para cuentas nuevas. No aplica en recontrataciones.',
  'PROFESIONAL MENSUAL': 'DESCUENTO POR PROGRAMA DE REFERIDOS. Válido durante los 4 primeros meses de suscripción. Promoción para cuentas nuevas. No aplica en recontrataciones.',
  'PROFESIONAL ANUAL': 'DESCUENTO POR PROGRAMA DE REFERIDOS. Válido durante el 1er año de suscripción. Promoción para cuentas nuevas. No aplica en recontrataciones.'
}

export const VOLUMEN_DISCOUNT_TEXT: { [key: string]: string } = {
  'PREMIUM MENSUAL': 'Descuento por volumen de usuarios desde el 10% hasta el 40% en función de la cantidad de usuarios que requiera',
  'PREMIUM ANUAL': 'Descuento por volumen de usuarios desde el 10% hasta el 40% en función de la cantidad de usuarios que requiera',
  'PROFESIONAL MENSUAL': 'Descuento por volumen de usuarios desde el 10% hasta el 40% en función de la cantidad de usuarios que requiera',
  'PROFESIONAL ANUAL': 'Descuento por volumen de usuarios desde el 10% hasta el 40% en función de la cantidad de usuarios que requiera'
}