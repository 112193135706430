<div class="row">
    <div class="container w-85 mt-5">
        <form action="">
            <section>
                <div class="row container-fluid">
                    <div class="users-form-bckgd">
                        <!-- PROCESSING WHEN STATE IS MICHOACAN -->
                        <div *ngIf="processingPayment" class="loader-overlay">
                            <div class="loader">
                                <h1>Falta poco...</h1>
                                <div class="spinner text-center"></div>
                            </div>
                        </div>

                        <div class="row justify-content-center d-flex">
                            <div class="col-lg-8">
                                <!-- CARD DE DESCUENTO PARA REFERIDOS -->
                                <mat-card-subtitle *ngIf="referred_id" style="color:#2761AD;">
                                    <b class="referred-title">
                                        ¡Genial, {{this.referredName}} te esta invitando a probar Contalink!
                                    </b>
                                    <br>
                                    <br>
                                    <span class="referred-description" style="font-size: 12px;">Y como beneficio
                                        obtienes <b>50%
                                            de descuento</b> en tus
                                        4
                                        primeras
                                        mensualidades si contratas
                                        el plan mensual, o si prefieres el plan anual, recibirás <b>4 meses de
                                            regalo</b>
                                        al contratar tu plan.</span>
                                </mat-card-subtitle>

                                <!-- CARD DE DESCUENTOS -->
                                <mat-card-subtitle *ngIf="!referred_id">
                                    <div class="new-header-text row">
                                        <div class="container">
                                            <div class="discount-text-circle col-lg-3">
                                                <div class="">
                                                    <p class="text-discount-black">
                                                        {{
                                                        (this.stateName === 'Michoacán' || this.stateName === 'Yucatán')
                                                        ?
                                                        '¡PROMOCIÓN!' : '¡DESCUENTO!'
                                                        }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div
                                                class="col-lg-9 text-header-div d-flex justify-content-center align-items-center">
                                                <p class="discount-gold-text">
                                                    {{ '50% de descuento en tus primeros 3 meses. En pago anual 25% de
                                                    descuento' }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </mat-card-subtitle>
                            </div>
                        </div>
                        <div class="row container-fluid" style="border: solid 1px;">
                            <div class="col-lg-10">
                                <div>
                                    <div class="col-lg-6">
                                        <div class="container-fluid">
                                            <div class="row logo-plan-info plan-features-background-color">
                                                <img src="https://contalink-reports.s3.amazonaws.com/PROD/LOGOS/CONTALINK/logo-white.png"
                                                    class="img-fluid" style="width: 20%; margin: 1em auto;" />
                                            </div>
                                            <div class="row plan-text-feature plan-features-background-color" [ngClass]="{
                                                'premium-text-color': plan?.plan_short_name === 'PREMIUM',
                                                'profesional-text-color': plan?.plan_short_name === 'PROFESIONAL'
                                                }">
                                                {{ (this.plan && this.plan?.plan_short_name | titlecase)}}
                                            </div>
                                            <div class="w-100 background-color-card row border-cards">
                                                <ul class="mt-10 list-features-text" #list_features_plan></ul>
                                            </div>
                                        </div>
                                        <div class="container-fluid col-lg-12 discount-text-promo mt-1">
                                            <div *ngIf="referred_id">
                                                {{plan && plan.discount_referres_text || ''}}
                                            </div>
                                            <br>
                                            <div *ngIf="((plan && plan.welcome_discount_text) && !referred_id)">
                                                {{plan && plan.welcome_discount_text || ''}}
                                            </div>
                                            <br>
                                            <div
                                                *ngIf="(plan && plan.volumen_discount_text && plan.number_of_users > 1)">
                                                {{plan && plan.volumen_discount_text || ''}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="border-cards row">
                                            <div class="row choice-plan-text ml-2">
                                                Elige tu plan:
                                            </div>
                                            <br>
                                            <div class="container-fluid row ml-1">
                                                <div class="col-lg-6">
                                                    <input type="radio" name="plan-button"
                                                        [class.selected]="selectedPlanOption === 'PROFESIONAL'"
                                                        (click)="selectOption('PROFESIONAL')">
                                                    &nbsp;<label for="profesion-button">Profesional</label>
                                                </div>
                                                <div class="col-lg-6">
                                                    <input type="radio" name="plan-button"
                                                        [class.selected]="selectedPlanOption === 'PREMIUM'"
                                                        (click)="selectOption('PREMIUM')" checked>
                                                    &nbsp;<label for="premium-button">Premium</label>
                                                </div>
                                            </div>

                                            <div class="row choice-plan-text ml-2">
                                                Plan de pago:
                                            </div>
                                            <br>
                                            <div class="container-fluid row ml-1">
                                                <div class="col-lg-6">
                                                    <input type="radio" name="payment-button" id="paymentPlanMensual"
                                                        [class.selected]="this.plan?.recurrence == 'MENSUAL'"
                                                        (click)="selectPaymentOption('MENSUAL')" checked>
                                                    &nbsp;
                                                    <label for="plan-button">
                                                        Mensual
                                                    </label>
                                                </div>
                                                <div class="col-lg-6">
                                                    <input type="radio" name="payment-button" id="paymentPlanAnual"
                                                        [class.selected]="this.plan?.recurrence == 'ANUAL'"
                                                        (click)="selectPaymentOption('ANUAL')">
                                                    &nbsp;<label for="plan-button">Anual</label>
                                                </div>
                                            </div>
                                            <br>
                                            <div class="container-fluid">
                                                <div class="col-lg-12"  style="border: solid 1px;">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2" style="border: solid 1px blue;">

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </form>
    </div>
</div>