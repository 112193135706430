import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SharedService } from '../components/shared-service/shared.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  constructor(private sharedService: SharedService, private cdr: ChangeDetectorRef) { }

  private subscription: Subscription;
  referredCode: string = '';

  ngOnInit(): void {
    this.subscription = this.sharedService.variable$.subscribe(value => {
      this.referredCode = value;
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
