<div class="container-fluid">
    <nav class="cl-navbar">
      <div class="navbar-header">
        <!--<a class="navbar-brand" routerLink="/inicio">-->
        <a class="navbar-brand" href="https://www.contalink.com/" 
          [style]="referredCode ? 'margin-left: 10vh;' : 'margin-left: 0;'">
          <img alt="Brand" src="/assets/webflow_img/logo.svg" height="32">
        </a>
        <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
          =
        </button>
      </div>
      <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
        <ul class="nav navbar-nav navbar-right" *ngIf="!referredCode">
          <li><a href="https://www.contalink.com/precios">Precios</a></li>
          <li><a href="https://app.contalink.com">Ingresar</a></li>
          <li><a href="https://www.contalink.com/contact" class="contact-btn">Contacto</a></li>
        </ul>
      </div><!-- /.navbar-collapse -->
    </nav>
    <router-outlet></router-outlet>
    <div *ngIf="referredCode" style="margin-top: 6em;"></div>
    <footer class="landing-footer">
      <div class="container">
        <div class="row">
          <div class="col-md-6 text-left">
            <a href="http://www.contalink.com"><img src="/assets/webflow_img/logo_white.svg" height="32" alt="ContaLink Logo"></a>
            <br>
            <br>
            <p>
              Calle Cocoyoc 3449 , Col Colonial La Silla, CP 64860, Monterrey, NL.<br>
              <!-- Tel: (81) 5350 3317 -->
            </p>
          </div>
          <div class="col-md-6 text-left">
            
          </div>
        </div>
      </div>
    </footer>
  </div>
  <div class="modal fade bs-example-modal-lg" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="videoModal" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
          <div>
            <iframe width="100%" height="600" src=""></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
  