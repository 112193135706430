import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';  // BehaviorSubject nos permite emitir valores y ser suscrito por los componentes

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private variableSubject = new BehaviorSubject<string>('');
  public variable$ = this.variableSubject.asObservable(); 

  constructor() { }

  setVariable(value: string): void {
    this.variableSubject.next(value);
  }
}
