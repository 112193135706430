<div class="principal-div">
    <div class="second-principal-div">
        <div class="w-100 container">
            <div class="row height-principal">
                <div class="col-lg-6" >
                    <div class="col-lg-12">
                        <p class="text-referrer-primary">{{ IReferrer?.name || '' }} te está refiriendo para que ahorres <b>80% de tiempo en tu contabilidad.</b></p>
                        <p class="text-referrer-primary mt-1">Y obtén hasta <b>4 meses gratis</b> en tu suscripción.</p>
                    </div>
                    <div class="col-lg-12 w-90 mt-1">
                        <div class="row">
                            <div class="col-lg-6 mb-2">
                                <div (click)='goToQuoter()' class="skyblue-buttons">Contratar ahora</div>
                            </div>
                            <div class="col-lg-6 mb-2">
                                <div (click)="goToLanding()" class="skyblue-buttons">Quiero información</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 d-flex">
                    <div class="d-flex flex-column">
                        <img class="referrer-img" src="assets/images/referrer_img.png" alt="">
                    </div>
                </div>
            </div>

            <div class="mt-4">
                <div class="col-12">
                    <div class="">
                        <div class="col-lg-4 col-xs-12 col-sm-12 mb-2">
                            <div class="row">
                                <img class="icons-size" src="assets/images/config.png" alt="">
                            </div>
                            <div class="row text-icon-size">
                                Automatización
                            </div> 
                            <div class="row description-text w-90">
                                Optimiza con la automatización de procesos repetitivos y ahorra 80% de tiempo.
                            </div>
                        </div>
                        <div class="col-lg-4 mb-2">
                            <div class="row">
                                <img class="icons-size" src="assets/images/puzzle.png" alt="">
                            </div>
                            <div class="row text-icon-size">
                                Todo en uno
                            </div>  
                            <div class="row description-text w-90">
                                Desde la descarga de CFDIs, facturación, nómina, reportes, cálculo de impuestos y más.
                            </div>
                        </div>
                        <div class="col-lg-4 mb-2">
                            <div class="row">
                                <img class="icons-size" src="assets/images/cloud.png" alt="">
                            </div>
                            <div class="row text-icon-size">
                                Trabaja en la nube
                            </div>  
                            <div class="row description-text w-90">
                                Accede a tu información desde cualquier computadora. Sin servidores ni instalaciones.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
