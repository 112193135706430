import { Injectable } from '@angular/core';
import { IPlanInfo } from '../interfaces/plan-info.interface';
import { CookieService } from 'ngx-cookie-service';
import { DISCOUNT_HEADER_TEXT, VOLUMEN_DISCOUNT_TEXT, DISCOUNT_TEXT, REFERRER_TEXT } from '../pages/users/list.data';


@Injectable({
    providedIn: 'root'
})
export class PlanService {
    discountHeaderTexts: any = DISCOUNT_HEADER_TEXT;
    discountTexts: any = DISCOUNT_TEXT;
    volumenDiscountTexts: any = VOLUMEN_DISCOUNT_TEXT;
    isReferer: boolean = false;

    constructor(private cookieService: CookieService) { }

    getPlans(plan: any, is_referer: boolean): IPlanInfo[] {
        this.isReferer = is_referer;
        const plans: IPlanInfo[] = [];
        plan.forEach((plan: any) => {
            plans.push(this.assignPlanDetails(plan));
        });

        return plans;
    }

    assignPlanDetails(plan: any): IPlanInfo {
        const locationName: string = this.cookieService.get('ipLocation');

        if (locationName === 'Yucatán' && !this.isReferer) {
            if (plan?.landing_freemium_label === 'PREMIUM MENSUAL')
                return this.assignYucatanPremiumPlanMensual(plan);
            else if (plan?.landing_freemium_label === 'PROFESIONAL MENSUAL')
                return this.assignYucatanProPlanMensual(plan);
            else
                return this.assignDefaultPlan(plan);
            
        } else if (locationName === 'Michoacán' && !this.isReferer) {
            if (plan?.landing_freemium_label === 'PREMIUM MENSUAL')
                return this.assignMichoacanPremiumPlanMensual(plan);
            else
                return this.assignDefaultPlan(plan);
        } else {
            return this.assignDefaultPlan(plan);
        }
    }

    private assignDefaultPlan(plan): IPlanInfo {
        const planInfo: IPlanInfo = {
            id: plan?.id,
            recurrence: plan?.recurrence,
            name: plan?.landing_freemium_label,
            price: plan?.price,
            plan_short_name: plan?.landing_freemium_label.split(' ')[0],
            // TEXTOS DE DESCUENTOS
            welcome_discount_text: this.discountTexts[plan?.landing_freemium_label],
            header_discount_text: this.discountHeaderTexts[plan?.landing_freemium_label],
            volumen_discount_text: this.volumenDiscountTexts[plan?.landing_freemium_label],
            discount_referres_text: REFERRER_TEXT[plan?.landing_freemium_label],
            welcome_discount: 0,
            welcome_discount_percentage: plan?.landing_freemium_discount,
            annual_discount_percentage: plan?.landing_freemium_discount,
            discount_users: plan?.landing_fremium_discount_users,
            landing_freemium_default_plan: plan?.landing_freemium_default_plan,
            number_of_users: 1,
            volumen_discount_percentage: 0,
            subtotal: 0,
            total_sale: 0,
            half_off_discount: 0,
            discount: 0,
            discount_deal: 0,
            is_referer: this.isReferer,
        };

        return planInfo;
    }

    private assignYucatanPremiumPlanMensual(plan: any): IPlanInfo {
        const planInfo: IPlanInfo = {
            id: plan?.id,
            recurrence: plan?.recurrence,
            name: plan?.landing_freemium_label,
            plan_short_name: plan?.landing_freemium_label.split(' ')[0],
            price: plan?.price,  // PRECIO DE YUCATAN
            welcome_discount_text: `Promoción de bienvenida 90% de descuento en el primer mes de suscripción. 
                            Promoción para cuentas nuevas. No aplica en recontrataciones`,
            welcome_discount: 0,
            discount_referres_text: REFERRER_TEXT[plan?.landing_freemium_label],
            welcome_discount_percentage: 90,
            header_discount_text: `Contrata por $99 + iva el primer Mes`,
            discount_users: plan?.landing_fremium_discount_users,
            landing_freemium_default_plan: plan?.landing_freemium_default_plan,
            number_of_users: 1,
            volumen_discount_percentage: 0,
            subtotal: 0,
            total_sale: 0,
            half_off_discount: 1,
            discount: 0,
            discount_deal: 0,
            annual_discount_percentage: 0,
            state_name: 'Yucatán',
            is_referer: this.isReferer,
            volumen_discount_text: 'Descuento por volumen no aplica con promoción de bienvenida $99 el primer mes. A partir del segundo mes en suscripción con precio regular se podrá aplicar descuento por volumen de usuarios.'
        }

        return planInfo;
    }

    private assignYucatanProPlanMensual(plan: any): IPlanInfo {
        const planInfo: IPlanInfo = {
            id: plan?.id,
            recurrence: plan?.recurrence,
            name: plan?.landing_freemium_label,
            plan_short_name: plan?.landing_freemium_label.split(' ')[0],
            price: plan?.price,  // PRECIO DE YUCATAN
            welcome_discount_text: `Promoción de bienvenida 83.22% de descuento en el primer mes de suscripción. 
                            Promoción para cuentas nuevas. No aplica en recontrataciones`,
            welcome_discount: 0,
            discount_referres_text: REFERRER_TEXT[plan?.landing_freemium_label],
            welcome_discount_percentage: 83.22,
            header_discount_text: `Contrata por $99 + iva el primer Mes`,
            discount_users: plan?.landing_fremium_discount_users,
            landing_freemium_default_plan: plan?.landing_freemium_default_plan,
            number_of_users: 1,
            volumen_discount_percentage: 0,
            subtotal: 0,
            total_sale: 0,
            half_off_discount: 1,
            discount: 0,
            discount_deal: 0,
            annual_discount_percentage: 0,
            state_name: 'Yucatán',
            is_referer: this.isReferer,
            volumen_discount_text: 'Descuento por volumen no aplica con promoción de bienvenida $99 el primer mes. A partir del segundo mes en suscripción con precio regular se podrá aplicar descuento por volumen de usuarios.'
        }

        return planInfo;
    }

    private assignMichoacanPremiumPlanMensual(plan: any): IPlanInfo {
        const planInfo: IPlanInfo = {
            id: plan?.id,
            recurrence: plan?.recurrence,
            name: plan?.landing_freemium_label,
            plan_short_name: plan?.landing_freemium_label.split(' ')[0],
            price: plan?.price,  // PRECIO DE MICHOACAN
            welcome_discount_text: `Promoción de bienvenida Gratis el primer mes de suscripción en Plan Premium. Promoción para cuentas nuevas. 
                                    No aplica en recontrataciones.`,
            welcome_discount: 0,
            discount_referres_text: REFERRER_TEXT[plan?.landing_freemium_label],
            welcome_discount_percentage: 0,
            header_discount_text: `GRATIS el primer mes de suscripción Plan Premium`,
            discount_users: plan?.landing_fremium_discount_users,
            landing_freemium_default_plan: plan?.landing_freemium_default_plan,
            number_of_users: 1,
            volumen_discount_percentage: 0,
            subtotal: 0,
            total_sale: 0,
            half_off_discount: 0,
            discount: 0,
            discount_deal: 0,
            annual_discount_percentage: plan?.landing_freemium_discount,
            state_name: 'Michoacán',
            is_referer: this.isReferer,
        }

        return planInfo;
    }
}