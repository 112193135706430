import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IpServiceService {
  private apiUrl = 'https://ipinfo.io/json?token=24a06a3d92dad8';

  constructor(private http: HttpClient) { }

  getIpInfo(): Observable<any> {
    return this.http.get<any>(this.apiUrl).pipe(
      catchError((error) => {
        console.error('Error al obtener la información de IP:', error);
        
        return of({ error: 'Error al obtener la información de IP' });
      })
    );
  }
}
