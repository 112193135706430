<notifier-container></notifier-container>
<!--<app-modal-rfc (closeModal)="closeModal()"></app-modal-rfc>
ModalRfcComponent-->
<app-modal-rfc (closeModal)="closeRFCModal()"></app-modal-rfc>
<mat-horizontal-stepper #stepper linear id="hzt_stepper">
  <mat-step [stepControl]="firstFormGroup" [completed]="stepOneCompleted">
    <form [formGroup]="firstFormGroup" (keydown.enter)="false">
      <ng-template matStepLabel>Usuarios y Planes</ng-template>
      <!-- P  R   I   M   E   R     P   A   S   O -->
      <section *ngIf="!isMobile" style="margin-bottom: 12.5em;">
        <div class="row container-fluid" [formGroup]="signupForm">

          <div class="users-form-bckgd" style="margin-top: 0em;">
            <!-- SI ES MICHOACAN AL MOMENTO DE PRESIONAR EL BOTON DE CREAR CUENTA SE HABILITA EL PROCESSING -->
            <div *ngIf="processingPayment" class="loader-overlay">
              <div class="loader">
                <h1>Falta poco...</h1>
                <div class="spinner text-center"></div>
              </div>
            </div>
            <!--  -->
            <div class="container-fluid col-lg-12" role="form" style="display: flex;">
              <mat-card id="mat-card-info-plan" style="background: none; box-shadow: none;">
                <div class="row container col-lg-11 col-lg-offset-1">
                  <div class="col-lg-10">
                    <div class="row text-center">
                      <div class="col-lg-offset-1 col-lg-10">
                        <mat-card-subtitle *ngIf="referred_id" style="color:#2761AD;">
                          <b class="referred-title" style="font-size: 15px;">
                            ¡Genial, {{this.referredName}} te esta invitando a probar Contalink!
                          </b>
                          <br>
                          <br>
                          <span class="referred-description" style="font-size: 12px;">Y como beneficio obtienes <b>50%
                              de descuento</b> en tus
                            4
                            primeras
                            mensualidades si contratas
                            el plan mensual, o si prefieres el plan anual, recibirás <b>4 meses de
                              regalo</b>
                            al contratar tu plan.</span>
                        </mat-card-subtitle>
                        <mat-card-subtitle *ngIf="!referred_id">
                          <div class="new-header-text row">
                            <div class="container">
                              <div class="discount-text-circle col-lg-3">
                                <div class="">
                                  <p style="font-size: 150%; padding-top: .4em; color: #202020;
                                    font-weight: 500;">
                                    {{
                                    (this.stateName === 'Michoacán' || this.stateName === 'Yucatán') ?
                                    '¡PROMOCIÓN!' : '¡DESCUENTO!'
                                    }}
                                  </p>
                                </div>
                              </div>
                              <div class="col-lg-9 text-header-div"
                                style="display: flex; justify-content: center; align-items: center; height: 100%;">
                                <p style="color: #FFC200; font-size: 22px; text-align: center; word-wrap: break-word;">
                                  {{planInformation?.header_discount_text || ''}}
                                </p>
                              </div>
                            </div>
                          </div>
                        </mat-card-subtitle>
                      </div>
                    </div>
                    <div class="row container-fluid">
                      <div class="col-lg-6 col-xl-6 container-fluid">
                        <div class="row col-lg-12 container-fluid">
                          <div id="logo-plan-info" class="col-lg-12" style="width: 105%; left: 0em;">
                            <img src="https://contalink-reports.s3.amazonaws.com/PROD/LOGOS/CONTALINK/logo-white.png"
                              class="img-fluid" style="width: 20%; margin: 1em auto;" />
                            <div class="col-12" id="title-plan" #title_plan
                              style="font-size: 300% !important; margin-bottom: .6em;">
                            </div>
                            <div style="display: none;" id="type_plan" #type_plan>Premium</div>
                            <div id="plan-info-card"></div>
                          </div>
                          <div class="col-lg-12 border-cards"
                            style="border-top-right-radius: 0; border-top-left-radius: 0; background-color: #f4f7fb; width: 105%;">
                            <br>
                            <ul id="list-features-plan" #list_features_plan
                              style="bottom: 0; left: 0; font-size: .8em; width: 90%;"></ul>
                          </div>
                          <div id="description-plan-info" #description_plan_info style="display: none;"></div>
                        </div>

                        <!-- DISCOUNT TEXTS -->
                        <div class="col-lg-12 container mt-10" style="display: none;">
                          <div class="row">
                            <div class="gradient-bg-cln-blue">
                              <div class="pb-5 px-5 d-flex justify-content-center align-items-center">
                                <div class="row">
                                  <div class="col-12 col-md-5 d-flex mb-3">
                                    <div class="col-12 col-md-4 col-lg-4"></div>
                                    <div class="col-12 col-md-8 col-lg-8 color-blue h1">
                                      Ambos Planes <span class="color-white">Incluyen</span>
                                    </div>
                                  </div>

                                  <div class="col-12 col-md-7 col-lg-7 ul-plans">
                                    <ul class="color-white no-decoreation">
                                      <li class="no-lyst">| RFCs ilimitados</li>
                                      <li class="no-lyst">| Descarga masiva de CFDIs</li>
                                      <li class="no-lyst">| Soporte</li>
                                      <li class="no-lyst">| Capacitación grupal en línea</li>
                                      <li class="no-lyst">| 4 sesiones personalizadas en línea</li>
                                      <li class="no-lyst">| Actualizaciones</li>
                                      <li class="no-lyst">| Servidores</li>
                                      <li class="no-lyst">| APP móvil</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12 container-fluid" style="margin-top: 1em;">
                          <div id="text-discount" style="font-size: .8em;">
                            <div *ngIf="referred_id">
                              {{planInformation && planInformation.discount_referres_text || ''}}
                            </div>
                            <br>
                            <div *ngIf="((planInformation && planInformation.welcome_discount_text) && !referred_id)">
                              {{planInformation && planInformation.welcome_discount_text || ''}}
                            </div>
                            <br>
                            <div
                              *ngIf="(planInformation && planInformation.volumen_discount_text && planInformation.number_of_users > 1)">
                              {{planInformation && planInformation.volumen_discount_text || ''}}
                            </div>
                            <div style="opacity: calc(0); width: 100em;"></div>
                          </div>
                        </div>
                      </div>
                      <!-- AQUI INICIA EL COTIZADOR QUE SE MUESTRA SOLO CUANDO EL ESTADO NO ES MICHOACAN-->
                      <div class="col-lg-6 col-xl-6" *ngIf="this.stateName !== 'Michoacán'">
                        <div class="col-lg-12 col-xl-6 border-cards"
                          style=" height: auto; left: 0em; margin-bottom: 1em;">
                          <div class="plan-card-text">
                            Elige tu plan:
                          </div>
                          <br>
                          <div>
                            <div class="row container-fluid" style="font-size: .9em;">
                              <div class="col-xs-7 col-md-6 col-lg-6" style="left: 1em;">
                                <input type="radio" name="plan-button"
                                  [class.selected]="selectedPlanOption == 'profesional'"
                                  (click)="selectOption('profesional')">
                                &nbsp;<label for="profesion-button">Profesional</label>
                              </div>
                              <div class="col-6">
                                <input type="radio" name="plan-button"
                                  [class.selected]="selectedPlanOption == 'premium'" (click)="selectOption('premium')"
                                  checked>
                                &nbsp;<label for="profesion-button">Premium</label>
                              </div>
                            </div>
                          </div>
                          <div class="plan-card-text">
                            Plan de pago:
                          </div>
                          <br>
                          <div style="margin-bottom: .5em;">
                            <div class="row container-fluid" style="font-size: .9em;">
                              <div class="col-xs-7 col-md-6 col-lg-6" style="left: 1em;">
                                <input type="radio" name="payment-button" id="paymentPlanMensual"
                                  [class.selected]="selectedPaymentOption == 'mensual'"
                                  (click)="selectPaymentOption('mensual')" checked>
                                &nbsp;
                                <label for="plan-button">
                                  Mensual
                                </label>
                              </div>
                              <div class="col-6">
                                <input type="radio" name="payment-button" id="paymentPlanAnual"
                                  [class.selected]="selectedPaymentOption == 'anual'"
                                  (click)="selectPaymentOption('anual')">
                                &nbsp;<label for="plan-button">Anual</label>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-8 col-md-8 col-xs-11 rounded"
                            style="border: solid .5px; border-radius: 1rem; 
                                border: 2px solid rgba(128, 128, 128, .2); left: 2em; height: 3em; margin-bottom: 1em !important;">
                            <div class="col-lg-4 col-xs-4 col-md-4">
                              <h4 style="font-size: 1.1em;">
                                Usuarios
                              </h4>
                            </div>
                            <div class="col-lg-8 col-xs-8 col-md-8" style="left: .8em;">
                              <div class="row">
                                <div class="col-lg-3 col-xs-2 col-md-2">
                                  <button mat-fab style="background-color: #fbc3c3;" class="button-width"
                                    (click)="userCount('less')">
                                    <mat-icon>remove</mat-icon>
                                  </button>
                                </div>
                                <div class="col-lg-offset-1 col-md-offset-1 col-xs-offset-1 "></div>
                                <div class="col-lg-3 col-md-2 col-xs-2" style="left: .8em;">
                                  <h4>{{planInformation && planInformation.number_of_users || ''}}</h4>
                                </div>
                                <div class="col-lg-3 col-xs-2 col-md-2">
                                  <button mat-fab class="button-width" style="background-color: rgba(0, 128, 0, 0.7);"
                                    (click)="userCount('plus')">
                                    <mat-icon>add</mat-icon>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br>
                        </div>
                        <div class="row" style="display: flex; 
                          flex-direction: column; 
                          width: 100%; margin-left: .1em;">
                          <div class="col-lg-12 border-cards"
                            style="width: 100%; left: 0em; background-color: rgb(240, 240, 240);">
                            <div class="justify-content-center">
                              <div class="container-fluid" style="margin-top: 1em;">
                                <div class="col-lg-8">
                                  <label for="" id="totals-block-one-label-subtotal" #totals_block_one_label_subtotal
                                    class="label-users-field-totals-2 total-text" style="right: 0em;"></label>
                                </div>
                                <div class="col-lg-4">
                                  <label for="" id="totals-block-one-value-subtotal" #totals_block_one_value_subtotal
                                    class="label-users-field-totals-2 total-text" style="right: 0em;"></label>
                                </div>
                              </div>
                              <div class="container-fluid">
                                <div class="col-lg-8">
                                  <label for="" id="totals-block-two-label-anual-discount"
                                    #totals_block_two_label_anual_discount class="label-users-field-totals-2 total-text"
                                    style="right: 0em;"></label>
                                </div>
                                <div class="col-lg-4">
                                  <label for="" id="totals-block-two-value-anual-discount"
                                    #totals_block_two_value_anua_discount class="label-users-field-totals-2 total-text"
                                    style="right: 0em;"></label>
                                </div>
                              </div>
                              <div class="container-fluid">
                                <div class="col-lg-8">
                                  <label for="" id="totals-block-three-label-vol-discount"
                                    #totals_block_three_label_vol_discount class="label-users-field-totals-2 total-text"
                                    style="right: 0em;"></label>
                                </div>
                                <div class="col-lg-4">
                                  <label for="" id="totals-block-three-value-vol-discount"
                                    #totals_block_three_value_vol_discount class="label-users-field-totals-2 total-text"
                                    style="right: 0em;"></label>
                                </div>
                              </div>
                              <div class="container-fluid">
                                <div class="col-lg-8">
                                  <label for="" id="totals-block-four-label-iva"
                                    class="label-users-field-totals-2 total-text" style="right: 0em;"></label>
                                </div>
                                <div class="col-lg-4">
                                  <label for="" id="totals-block-four-value-iva"
                                    class="label-users-field-totals-2 total-text" style="right: 0em;"></label>
                                </div>
                              </div>
                              <div class="container-fluid" style="margin-bottom: .5em;">
                                <div class="col-lg-8">
                                  <label for="" id="totals-block-five-label-total"
                                    class="label-users-field-totals-2 total-text" style="right: 0em;"></label>
                                </div>
                                <div class="col-lg-4"><label id="totals-block-five-value-total"
                                    class="label-users-field-totals-2 total-text" style="right: 0em;"></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br>
                        <!-- BOTON SIGUIENTE -->
                        <div class="col-lg-12" style="width: 100%; height: auto; left: 0em;">
                          <div [hidden]="!stepOneCompleted">
                            <button mat-button (click)="goStepTwo();" id="" style="background-color:#0275d8;
                                  font-size: .9em;
                                  color: white; 
                                  border-radius: 15px / 50%;
                                  width: 100%;" matStepperNext>Siguiente</button>
                          </div>
                        </div>
                      </div>
                      <!-- INICIA FORMULARIO SI EL ESTADO ES MICHOACAN -->
                      <div class="col-lg-6 col-xl-6" *ngIf="this.stateName === 'Michoacán'">
                        <div class="col-lg-12 col-xl-6 border-cards"
                          style=" height: auto; left: 0em; margin-bottom: 1em;">
                          <div class="users-form-bckgd" style="margin-top: 1em;">
                            <div class="container" style="width: 95%;">
                              <h2 style="color: #0275d8; font-weight: 700; font-size: 20px;" class="lexend">
                                Regístrate
                              </h2>
                              <form [formGroup]="secondFormGroup" (keydown.enter)="false">
                                <div class="container-fluid row" style="padding-left: 0px; padding-right: 0px;">
                                  <div class="form-group col-lg-6 col-md-6 col-xs-6">
                                    <label for="" class="input-label">Nombre completo</label>
                                    <input (input)="onCapture('name')" type="text"
                                      class="form-control input-form-height" formControlName="name"
                                      [ngClass]="{'': is_valid }" id="name">
                                  </div>
                                  <div class="form-group col-lg-6 col-md-6 col-xs-6">
                                    <label for="" class="input-label">Correo (usuario)</label>
                                    <input (input)="onCapture('email')" type="email"
                                      class="form-control input-form-height" formControlName="email"
                                      [ngClass]="{'': is_valid }" id="email" #email>
                                  </div>
                                </div>
                                <div class="container-fluid row" style="padding-left: 0px; padding-right: 0px;">
                                  <div class="form-group col-lg-6 col-md-6 col-xs-6">
                                    <label for="" class="input-label">Celular</label>
                                    <input (input)="onCapture('phone')" type="text"
                                      class="form-control input-form-height" formControlName="phone"
                                      [ngClass]="{'': is_valid }" id="phone" #phone>
                                    <div class="row container-fluid">
                                      <label style="font-weight: 600; font-size: 11px; color: #6E6E6E;">
                                        Whatsapp para soporte y capacitación gratuitos
                                      </label>
                                    </div>
                                  </div>
                                  <div class="form-group col-lg-5 col-md-5 col-xs-5" style="display: none;">
                                    <label for="" class="input-label">Genera tu contraseña</label>
                                    <input (input)="onCapture('userpass')" type="password"
                                      [type]="fieldTextType ? 'text' : 'password'"
                                      class="form-control input-form-height" id="userpass" formControlName="userpass"
                                      [ngClass]="{'': is_valid }">
                                  </div>
                                  <div class="form-group col-lg-1 col-md-1 col-xs-1" style="display: none;">
                                    <mat-icon style="position: relative; top: 1.1em;" class="password-toggle"
                                      (click)="toggleFieldTextType()">{{fieldTextType ? 'visibility' :
                                      'visibility_off'}}</mat-icon>
                                  </div>
                                </div>
                                <h4 style="color: #0275d8; font-weight: 700; font-size: 14px; margin-top: 0;"
                                  class="lexend">
                                  Datos primer empresa a operar en contalink.
                                </h4>
                                <div class="container-fluid row" style="padding-left: 0px; padding-right: 0px;">
                                  <div class="form-group col-lg-6 col-md-6 col-xs-6">
                                    <label for="" class="input-label">Rázon Social</label>
                                    <input (input)="onCapture('company')" type="text"
                                      class="form-control input-form-height" formControlName="company"
                                      [ngClass]="{'': is_valid }" id="company">
                                  </div>
                                  <div class="form-group col-lg-6 col-md-6 col-xs-6">
                                    <label for="" class="input-label">RFC</label>
                                    <input (input)="onCapture('rfc')" type="text" class="form-control input-form-height"
                                      formControlName="rfc" [ngClass]="{'': is_valid }" id="rfc" #rfc>
                                  </div>
                                </div>
                                <h4 style="color: #0275d8; font-weight: 700; font-size: 14px; margin-top: 0;"
                                  class="lexend">
                                  Cuéntanos sobre ti:
                                </h4>
                                <div class="container-fluid row" style="padding-left: 0px; padding-right: 0px;">
                                  <div class="form-group col-lg-6 col-md-6 col-xs-6">
                                    <label for="" class="input-label">Giro de tu negocio</label>
                                    <select name="line_of_business" id="line_of_business"
                                      class="form-control input-form-height" (change)="onChangeLineOfBusiness()">
                                      <optgroup class="label-users-field">
                                        <option style="display:none;"></option>
                                        <option *ngFor="let regimen of lineOfBusinessList" [value]="regimen.id"
                                          [attr.data-value]="regimen.id" [attr.data-id]="regimen.id">
                                          {{regimen.value}}
                                        </option>
                                      </optgroup>>
                                    </select>
                                  </div>
                                  <div class="form-group col-lg-6 col-md-6 col-xs-6">
                                    <label for="" class="input-label">Número de contadores</label>
                                    <input (input)="onCapture('company')" type="number" #number_of_users
                                      class="form-control input-form-height" [ngClass]="{'': is_valid }"
                                      id="number_of_users" min="1" max="99" value="1">
                                  </div>
                                </div>
                                <div class="container-fluid row" style="padding-left: 0px; padding-right: 0px;">
                                  <div class="form-group col-lg-6 col-md-6 col-xs-6">
                                    <label for="" class="input-label">¿Cómo escucho de nosotros?</label>
                                    <select name="pipedrive_referrence" id="pipedrive_reference"
                                      class="form-control input-form-height" (change)="onChangePipedriveReference()">
                                      <optgroup class="label-users-field">
                                        <option style="display:none;"></option>
                                        <option *ngFor="let reference of pipedriveReferenceList" [value]="reference.id"
                                          [attr.data-value]="reference.value" [attr.data-id]="reference.id">
                                          {{reference.value}}
                                        </option>
                                      </optgroup>
                                    </select>  
                                  </div>
                                </div>
                                <div class="container-fluid row" style="padding-left: 0px; padding-right: 0px;">
                                  <div class="col-lg-1" style="padding-right: 0%;">
                                    <input type="checkbox" name="" style="width:15px;" id="accept_tyc"
                                      (change)="acceptedTyC = !acceptedTyC">
                                  </div>
                                  <div class="col-lg-11" style="padding-left: 0%;">
                                    <label class="acept-terms-and-conditions">
                                      Acepto los
                                      <a href="assets/TERMINOS Y CONDICIONES CONTALINK 08_23.pdf" target="_blank"
                                        rel="noopener noreferrer" (change)="acceptedTyC = !acceptedTyC">
                                        términos y condiciones
                                      </a>
                                    </label>
                                  </div>
                                </div>
                                <div class="container-fluid row" style="padding-left: 0px; padding-right: 0px;">
                                  <div class="col-lg-1" style="padding-right: 0%;">
                                    <input type="checkbox" name="" style="width:15px;" id="acceptedEmailPromotional"
                                      (click)="changeAcceptedPromotionalEmail()" checked>
                                  </div>
                                  <div class="col-lg-11" style="padding-left: 0%;">
                                    <label class="acept-terms-and-conditions">
                                      Acepto recibir correos electrónicos de comunicados y promociones.
                                    </label>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>

                        <!-- BOTON SIGUIENTE -->
                        <div class="col-lg-12" style="width: 100%; height: auto; left: 0em;">
                          <div>
                            <button mat-button (click)="MichoacanPostSlack();" id="" [disabled]="!acceptedTyC"
                              [ngClass]="{ 'active-button': acceptedTyC, 'inactive-button': !acceptedTyC }" style="font-size: .9em; color: white; border-radius: 15px / 50%; 
                              width: 100%;" matStepperNext>
                              Crear cuenta
                            </button>
                          </div>
                        </div>
                      </div>
                      <!-- TERMINA EL FORMULARIO PARA ESTADO DE MICHOACAN -->
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <!-- AQUI VA EL TESTIMONIO, HAY QUE MODIFICARLO PARA QUE QUEPA AQUI, cuando sea para telefono quitarlo -->
                    <div class="testimonial-image-container">
                      <img class="testimonial-image" src="assets/testimonio.png" alt="Contador Publico">
                      <div class="testimonial-label">
                        "Ahorramos hasta un 80% del tiempo" <br>
                        <span>-C.P. Aldo Fabrizzio</span>
                      </div>
                    </div>
                  </div>
                </div>

              </mat-card>
            </div>
          </div>
        </div>
      </section>

      <!-- MOBIL -->
      <section *ngIf="isMobile">
        <div class="row" [formGroup]="signupForm">
          <div class="users-form-bckgd" style="margin-top: 2em">
            <div class="container">
              <div class="users-form form-inline" role="form">
                <mat-card id="" style="box-shadow: none !important;">
                  <div class="row">
                    <div class="col-12 col-md-10 mx-auto">
                      <!-- SUBTITLE GRACIAS POR USAR CONTILINK REFERIDOS -->
                      <mat-card-subtitle *ngIf="referred_id" class="text-center"
                        style="color:#2761AD; position: relative;">
                        <b class="referred-title">
                          ¡Genial, {{this.referredName}} te esta invitando a probar Contalink!
                        </b>
                        <br><br>
                        <span class="referred-description" style="font-size: 12px;">Y como beneficio obtienes <b>50%
                            de descuento</b> en tus
                          4
                          primeras
                          mensualidades si contratas
                          el plan mensual, o si prefieres el plan anual, recibirás <b>4 meses de
                            regalo</b>
                          al contratar tu plan.</span>
                      </mat-card-subtitle>
                      <mat-card-subtitle>
                        <div class="new-header-text row">
                          <div class="container">
                            <div class="discount-text-circle col-2">
                              <div class="">
                                <p style="font-size: 20px; padding-top: .4em; color: #202020;
                                  font-weight: 500;">
                                  ¡DESCUENTO!
                                </p>
                              </div>
                            </div>
                            <div class="col-12 text-header-div">
                              <p style="color: #FFC200; font-size: 12px; margin-bottom: 65px;">
                                {{planInformation?.header_discount_text || ''}}
                              </p>
                            </div>
                          </div>
                        </div>
                      </mat-card-subtitle>
                      <br>
                      <!-- TERMINA SUBTITLE GRACIAS POR USAR CONTILINK -->
                    </div>
                  </div>

                  <div class="row container"
                    style="display: flex; flex-direction: column; width: 100%; margin-left: .1em;">
                    <div id="logo-plan-info" class="col-lg-12" style="width: 100%; height: auto; left: 0em;">
                      <img src="https://contalink-reports.s3.amazonaws.com/PROD/LOGOS/CONTALINK/logo-white.png"
                        class="img-fluid" style="width: 40%; margin: 1em auto;" />
                      <div class="col-12" id="title-plan" style="font-size: 300% !important;" #title_plan>
                        Profesional
                      </div>
                      <div style="display: none;" id="type_plan" #type_plan>Premium</div>
                      <div id="plan-info-card"></div>
                    </div>
                    <div class="col-lg-12 border-cards" style="border-top-right-radius: 0; border-top-left-radius: 0;">
                      <br>
                      <ul id="list-features-plan" #list_features_plan style="bottom: 0; left: 0; font-size: 14px;"></ul>
                    </div>
                    <div id="description-plan-info" style="display: none;" #description_plan_info></div>
                  </div>
                  <br>
                  <!-- AQUI INICIA EL COTIZADOR QUE SE MUESTRA SOLO CUANDO EL ESTADO NO ES MICHOACAN MOBIL-->
                  <div *ngIf="this.stateName !== 'Michoacán'" class="row container"
                    style="display: flex; flex-direction: column; width: 100%; margin-left: .1em;">
                    <div class="col-lg-12 border-cards" style="width: 100%; height: auto; left: 0em;">
                      <div class="plan-card-text">
                        Elige tu plan:
                      </div>
                      <br>
                      <div>
                        <div class="row container-fluid">
                          <div class="col-xs-7 col-md-6 col-lg-6" style="left: 1em;">
                            <input type="radio" name="plan-button"
                              [class.selected]="selectedPlanOption == 'profesional'"
                              (click)="selectOption('profesional')" checked>
                            &nbsp;<label for="profesion-button">Profesional</label>
                          </div>
                          <div class="col-6">
                            <input type="radio" name="plan-button" [class.selected]="selectedPlanOption == 'premium'"
                              (click)="selectOption('premium')">
                            &nbsp;<label for="profesion-button">Premium</label>
                          </div>
                        </div>
                      </div>
                      <div class="plan-card-text">
                        Plan de pago:
                      </div>
                      <br>
                      <div>
                        <div class="row container-fluid">
                          <div class="col-xs-7 col-md-6 col-lg-6" style="left: 1em;">
                            <input type="radio" name="payment-button" id="paymentPlanMensual"
                              [class.selected]="selectedPaymentOption == 'mensual'"
                              (click)="selectPaymentOption('mensual')" checked
                              [disabled]="this.stateName === 'Yucatán' && this.planInformation?.plan_short_name == 'PROFESIONAL'"
                              [style.background-color]="(this.stateName === 'Yucatán' && this.planInformation?.plan_short_name == 'PROFESIONAL') ? 'gray' : ''">

                            &nbsp;<label for="plan-button">Mensual</label>
                          </div>
                          <div class="col-6">
                            <input type="radio" name="payment-button"
                              [class.selected]="selectedPaymentOption == 'anual'"
                              (click)="selectPaymentOption('anual')">
                            &nbsp;<label for="plan-button">Anual</label>
                          </div>
                        </div>
                      </div>
                      <br><br>
                      <div class="col-lg-6 col-md-6 col-xs-10 rounded" style="border: solid .5px; border-radius: 1rem; 
                        border: 2px solid rgba(128, 128, 128, .2); left: 2em; height: 4em;">
                        <div class="col-lg-4 col-xs-4 col-md-4">
                          <h4 class="label-users-field-totals-2" id="user-label-container">
                            Usuarios
                          </h4>
                        </div>
                        <div class="col-lg-8 col-xs-8 col-md-8" style="top: .6em; left: .5em;">
                          <div class="row">
                            <div class="col-lg-3 col-xs-2 col-md-2">
                              <button mat-fab style="background-color: #fbc3c3;" class="button-width"
                                (click)="userCount('less')">
                                <mat-icon>remove</mat-icon>
                              </button>
                            </div>
                            <div class="col-lg-offset-1 col-md-offset-1 col-xs-offset-1 "></div>
                            <div class="col-lg-3 col-md-2 col-xs-2" style="left: .8em;">
                              <h4>{{planInformation && planInformation.number_of_users || '1'}}</h4>
                              <!-- <h4>{{usersCount}}</h4> -->
                            </div>
                            <div class="col-lg-3 col-xs-2 col-md-2">
                              <button mat-fab class="button-width" style="background-color: rgba(0, 128, 0, 0.7);"
                                (click)="userCount('plus')">
                                <mat-icon>add</mat-icon>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br>
                    </div>
                    <br>
                  </div>
                  <!--  -->

                  <div *ngIf="this.stateName !== 'Michoacán'" class="row container"
                    style="display: flex; flex-direction: column; width: 100%; margin-left: .1em;">
                    <div class="col-lg-12 border-cards" style="width: 100%; height: auto; left: 0em;">
                      <div class="container">
                        <div class="col-xs-6">
                          <label for="" id="totals-block-one-label-subtotal" #totals_block_one_label_subtotal
                            class="label-users-field-totals-2 total-text" style="right: 0em;"></label>
                        </div>
                        <div class="col-xs-6">
                          <label for="" id="totals-block-one-value-subtotal" #totals_block_one_value_subtotal
                            class="label-users-field-totals-2 total-text" style="right: 0em;"></label>
                        </div>
                      </div>
                      <div class="container">
                        <div class="col-xs-6">
                          <label for="" id="totals-block-two-label-anual-discount"
                            #totals_block_two_label_anual_discount class="label-users-field-totals-2 total-text"
                            style="right: 0em;"></label>
                        </div>
                        <div class="col-xs-6">
                          <label for="" id="totals-block-two-value-anual-discount"
                            #totals_block_two_value_anual_discount class="label-users-field-totals-2 total-text"
                            style="right: 0em;"></label>
                        </div>
                      </div>
                      <div class="container">
                        <div class="col-xs-6">
                          <label for="" id="totals-block-three-label-vol-discount"
                            #totals_block_three_label_vol_discount class="label-users-field-totals-2 total-text"
                            style="right: 0em;"></label>
                        </div>
                        <div class="col-xs-6">
                          <label for="" id="totals-block-three-value-vol-discount"
                            #totals_block_three_value_vol_discount class="label-users-field-totals-2 total-text"
                            style="right: 0em;"></label>
                        </div>
                      </div>
                      <div class="container">
                        <div class="col-xs-6">
                          <label for="" id="totals-block-four-label-iva" class="label-users-field-totals-2 total-text"
                            style="right: 0em;"></label>
                        </div>
                        <div class="col-xs-6">
                          <label for="" id="totals-block-four-value-iva" class="label-users-field-totals-2 total-text"
                            style="right: 0em;"></label>
                        </div>
                      </div>
                      <div class="container">
                        <div class="col-xs-6">
                          <label for="" id="totals-block-five-label-total" class="label-users-field-totals-2 total-text"
                            style="right: 0em;"></label>
                        </div>
                        <div class="col-xs-6"><label id="totals-block-five-value-total"
                            class="label-users-field-totals-2 total-text" style="right: 0em;"></label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- INICIA FORMULARIO SI EL ESTADO ES MICHOACAN MOBIL-->
                  <div class="col-lg-6 col-xl-6" *ngIf="this.stateName === 'Michoacán'">
                    <div class="col-lg-12 col-xl-6 border-cards" style=" height: auto; left: 0em; margin-bottom: 1em;">
                      <div class="users-form-bckgd" style="margin-top: 1em;">
                        <div class="container" style="width: 95%;">
                          <h2 style="color: #0275d8; font-weight: 700; font-size: 20px;" class="lexend">
                            Regístrate
                          </h2>
                          <form [formGroup]="secondFormGroup" (keydown.enter)="false">
                            <div class="container-fluid row" style="padding-left: 0px; padding-right: 0px;">
                              <div class="form-group col-lg-6 col-md-6 col-xs-6">
                                <label for="" class="input-label">Nombre completo</label>
                                <input (input)="onCapture('name')" type="text" class="form-control input-form-height"
                                  formControlName="name" [ngClass]="{'': is_valid }" id="name">
                              </div>
                              <div class="form-group col-lg-6 col-md-6 col-xs-6">
                                <label for="" class="input-label">Correo (usuario)</label>
                                <input (input)="onCapture('email')" type="email" class="form-control input-form-height"
                                  formControlName="email" [ngClass]="{'': is_valid }" id="email" #email>
                              </div>
                            </div>
                            <div class="container-fluid row" style="padding-left: 0px; padding-right: 0px;">
                              <div class="form-group col-lg-6 col-md-6 col-xs-6">
                                <label for="" class="input-label">Celular</label>
                                <input (input)="onCapture('phone')" type="text" class="form-control input-form-height"
                                  formControlName="phone" [ngClass]="{'': is_valid }" id="phone" #phone>
                                <div class="row container-fluid">
                                  <label style="font-weight: 600; font-size: 11px; color: #6E6E6E;">
                                    Whatsapp para soporte y capacitación gratuitos
                                  </label>
                                </div>
                              </div>
                              <div class="form-group col-lg-5 col-md-5 col-xs-5" style="display: none;">
                                <label for="" class="input-label">Genera tu contraseña</label>
                                <input (input)="onCapture('userpass')" type="password"
                                  [type]="fieldTextType ? 'text' : 'password'" class="form-control input-form-height"
                                  id="userpass" formControlName="userpass" [ngClass]="{'': is_valid }">
                              </div>
                              <div class="form-group col-lg-1 col-md-1 col-xs-1" style="display: none;">
                                <mat-icon style="position: relative; top: 1.1em;" class="password-toggle"
                                  (click)="toggleFieldTextType()">{{fieldTextType ? 'visibility' :
                                  'visibility_off'}}</mat-icon>
                              </div>
                            </div>
                            <h4 style="color: #0275d8; font-weight: 700; font-size: 14px; margin-top: 0;"
                              class="lexend">
                              Datos primer empresa a operar en contalink.
                            </h4>
                            <div class="container-fluid row" style="padding-left: 0px; padding-right: 0px;">
                              <div class="form-group col-lg-6 col-md-6 col-xs-6">
                                <label for="" class="input-label">Rázon Social</label>
                                <input (input)="onCapture('company')" type="text" class="form-control input-form-height"
                                  formControlName="company" [ngClass]="{'': is_valid }" id="company">
                              </div>
                              <div class="form-group col-lg-6 col-md-6 col-xs-6">
                                <label for="" class="input-label">RFC</label>
                                <input (input)="onCapture('rfc')" type="text" class="form-control input-form-height"
                                  formControlName="rfc" [ngClass]="{'': is_valid }" id="rfc" #rfc>
                              </div>
                            </div>
                            <h4 style="color: #0275d8; font-weight: 700; font-size: 14px; margin-top: 0;"
                              class="lexend">
                              Cuéntanos sobre ti:
                            </h4>
                            <div class="container-fluid row" style="padding-left: 0px; padding-right: 0px;">
                              <div class="form-group col-lg-6 col-md-6 col-xs-6">
                                <label for="" class="input-label">Giro de tu negocio</label>
                                <select name="regimen_fiscal" id="regimen_fiscal" class="form-control input-form-height"
                                  (change)="onChangeRegimen()">
                                  <optgroup class="label-users-field">
                                    <option style="display:none;"></option>
                                    <option *ngFor="let regimen of regimenList" [value]="regimen.id"
                                      [attr.data-value]="regimen.value" [attr.data-id]="regimen.id">
                                      {{regimen.value}}
                                    </option>
                                  </optgroup>
                                </select>
                              </div>
                              <div class="form-group col-lg-6 col-md-6 col-xs-6">
                                <label for="" class="input-label">Número de contadores</label>
                                <input (input)="onCapture('company')" type="number" #number_of_users
                                  class="form-control input-form-height" [ngClass]="{'': is_valid }"
                                  id="number_of_users" min="1" max="99" value="1">
                              </div>
                            </div>
                            <div class="container-fluid row" style="padding-left: 0px; padding-right: 0px;">
                              <div class="form-group col-lg-6 col-md-6 col-xs-6">
                                <label for="" class="input-label">¿Cómo escucho de nosotros?</label>
                                <select name="pipedrive_referrence" id="pipedrive_reference"
                                  class="form-control input-form-height" (change)="onChangePipedriveReference()">
                                  <optgroup class="label-users-field">
                                    <option style="display:none;"></option>
                                    <option *ngFor="let reference of pipedriveReferenceList" [value]="reference.id"
                                      [attr.data-value]="reference.value" [attr.data-id]="reference.id">
                                      {{reference.value}}
                                    </option>
                                  </optgroup>
                                </select>
                              </div>
                            </div>
                            <div class="container-fluid row" style="padding-left: 0px; padding-right: 0px;">
                              <div class="col-lg-1" style="padding-right: 0%;">
                                <input type="checkbox" name="" style="width:15px;" id="accept_tyc"
                                  (change)="acceptedTyC = !acceptedTyC">
                              </div>
                              <div class="col-lg-11" style="padding-left: 0%;">
                                <label class="acept-terms-and-conditions">
                                  Acepto los
                                  <a href="assets/TERMINOS Y CONDICIONES CONTALINK 08_23.pdf" target="_blank"
                                    rel="noopener noreferrer" (change)="acceptedTyC = !acceptedTyC">
                                    términos y condiciones
                                  </a>
                                </label>
                              </div>
                            </div>
                            <div class="container-fluid row" style="padding-left: 0px; padding-right: 0px;">
                              <div class="col-lg-1" style="padding-right: 0%;">
                                <input type="checkbox" name="" style="width:15px;" id="acceptedEmailPromotional"
                                  (click)="changeAcceptedPromotionalEmail()" checked>
                              </div>
                              <div class="col-lg-11" style="padding-left: 0%;">
                                <label class="acept-terms-and-conditions">
                                  Acepto recibir correos electrónicos de comunicados y promociones.
                                </label>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                    <!-- BOTON SIGUIENTE -->
                    <div class="col-lg-12" style="width: 100%; height: auto; left: 0em;">
                      <div>
                        <button mat-button (click)="MichoacanPostSlack();" id="" [disabled]="!acceptedTyC"
                          [ngClass]="{ 'active-button': acceptedTyC, 'inactive-button': !acceptedTyC }" style="font-size: .9em; color: white; border-radius: 15px / 50%; 
                          width: 100%;" matStepperNext>
                          Crear cuenta
                        </button>
                      </div>
                    </div>
                  </div>
                  <!-- TERMINA EL FORMULARIO PARA ESTADO DE MICHOACAN -->

                  <br>
                  <div class="row container"
                    style="display: flex; flex-direction: column; width: 100%;  margin-left: .1em;">
                    <div class="col-lg-12" style="width: 100%; height: auto; left: 0em;">
                      <div id="text-discount">
                        <div *ngIf="this.discount_referres_message"> {{this.discount_referres_message}} </div>
                        <br>
                        <div *ngIf="this.discount_message"> {{this.discount_message}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="row container"
                    style="display: flex; flex-direction: column; width: 100%;  margin-left: .1em;">
                    <div class="col-lg-12" style="width: 100%; height: auto; left: 0em;">
                      <div [hidden]="!stepOneCompleted">
                        <button mat-button (click)="goStepTwo();" id="" style="background-color:#0275d8;
                            color: white; 
                            border-radius: 15px / 50%;
                            width: 100%;" matStepperNext>Siguiente</button>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- TERMINA MOBIL -->
    </form>
  </mat-step>




  <!-- P  R   I   M   E   R     P   A   S   O     F   I   N   -->
  <!-- SEGUNDO PASO -->
  <mat-step [stepControl]="secondFormGroup" completed="stepTwoCompleted">
    <form [formGroup]="secondFormGroup" (keydown.enter)="false">
      <ng-template matStepLabel>Información de Registro</ng-template>
      <div class="users-form-bckgd" style="margin-top: 1em;">
        <div class="row">
          <div class="col-lg-offset-2 col-lg-8 container-fluid">
            <div class="row" style="height: 39.5em;" id="second-step-form-div">
              <div id="image-form-mobil" class="col-lg-5 col-xs-12 col-md-5 border-cards overlay"
                style="
                   border-top-right-radius: 0px;
                   border-bottom-right-radius: 0px;
                   display: flex;
                   justify-content: center;
                   align-items: center;
                   height: 100%;
                   font-weight: 600;
                   font-size: 1.8em;
                   font-family: Lexend;
                   background: url('https://contalink-reports.s3.amazonaws.com/PROD/LOGOS/CONTALINK/background_form.png');">
                <div class="container">
                  <div class="col-lg-12">
                    <label style="display: flex; justify-content: center; color: white;">
                      Para el contador
                    </label>
                  </div>
                  <div class="col-lg-12">
                    <label style="display: flex; justify-content: center; color: white;">
                      que busca el cambio,
                    </label>
                  </div>
                  <div class="col-lg-12">
                    <label style="display: flex; justify-content: center; color: #12EFF9;;">
                      bienvenido a Contalink.
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-lg-7 col-xs-12 col-md-7 border-cards container-fluid" style="background-color: #fdfbfb; height: 39.5em;
                 font-family: Lexend;
                 border-top-left-radius: 0px;
                 border-bottom-left-radius: 0px;">
                <div class="container-fluid h-100">
                  <div class="row h-100">
                    <br>
                    <div class="col-lg-offset-1 col-lg-10 col-md-10 col-md-offset-1"
                      style="height: 42em; bottom: 1.5em;">
                      <h2 style="color: #0275d8; font-weight: 700; font-size: 20px;" class="lexend">Regístrate</h2>

                      <div class="container-fluid row" style="padding-left: 0px; padding-right: 0px;">
                        <div class="form-group col-lg-6 col-md-6 col-xs-6">
                          <label for="" class="input-label">Nombre completo</label>
                          <input (input)="onCapture('name')" type="text" class="form-control input-form-height"
                            formControlName="name" [ngClass]="{'': is_valid }" id="name">
                        </div>
                        <div class="form-group col-lg-6 col-md-6 col-xs-6">
                          <label for="" class="input-label">Correo (usuario)</label>
                          <input (input)="onCapture('email')" type="email" class="form-control input-form-height"
                            formControlName="email" [ngClass]="{'': is_valid }" id="email" #email>
                        </div>
                      </div>
                      <div class="container-fluid row" style="padding-left: 0px; padding-right: 0px;">
                        <div class="form-group col-lg-6 col-md-6 col-xs-6">
                          <label for="" class="input-label">Celular</label>
                          <input (input)="onCapture('phone')" type="text" class="form-control input-form-height"
                            formControlName="phone" [ngClass]="{'': is_valid }" id="phone" #phone>
                          <div class="row container-fluid">
                            <label style="font-weight: 600; font-size: 11px; color: #6E6E6E;">
                              Whatsapp para soporte y capacitación gratuitos
                            </label>
                          </div>
                        </div>
                        <div class="form-group col-lg-5 col-md-5 col-xs-5" style="display: none;">
                          <label for="" class="input-label">Genera tu contraseña</label>
                          <input (input)="onCapture('userpass')" type="password"
                            [type]="fieldTextType ? 'text' : 'password'" class="form-control input-form-height"
                            id="userpass" formControlName="userpass" [ngClass]="{'': is_valid }">
                        </div>
                        <div class="form-group col-lg-1 col-md-1 col-xs-1" style="display: none;">
                          <mat-icon style="position: relative; top: 1.1em;" class="password-toggle"
                            (click)="toggleFieldTextType()">{{fieldTextType ? 'visibility' :
                            'visibility_off'}}</mat-icon>
                        </div>
                      </div>
                      <h4 style="color: #0275d8; font-weight: 700; font-size: 14px; margin-top: 0;" class="lexend">Datos
                        de facturación</h4>
                      <div class="container-fluid row" style="padding-left: 0px; padding-right: 0px;">
                        <div class="form-group col-lg-6 col-md-6 col-xs-6">
                          <label for="" class="input-label">Rázon Social</label>
                          <input (input)="onCapture('company')" type="text" class="form-control input-form-height"
                            formControlName="company" [ngClass]="{'': is_valid }" id="company">
                        </div>
                        <div class="form-group col-lg-6 col-md-6 col-xs-6">
                          <label for="" class="input-label">RFC</label>
                          <input (input)="onCapture('rfc')" type="text" class="form-control input-form-height"
                            formControlName="rfc" [ngClass]="{'': is_valid }" id="rfc" #rfc>
                        </div>
                      </div>
                      <div class="container-fluid row" style="padding-left: 0px; padding-right: 0px;">
                        <div class="form-group col-lg-6 col-md-6 col-xs-6">
                          <label for="" class="input-label">Código Postal</label>
                          <input (input)="onCapture('postal_code')" type="text" class="form-control input-form-height"
                            formControlName="postal_code" [ngClass]="{'': is_valid }" id="postal_code" #postal_code>
                        </div>
                        <div class="form-group col-lg-6 col-md-6 col-xs-6">
                          <label for="" class="input-label">Régimen Fiscal</label>
                          <select name="regimen_fiscal" id="regimen_fiscal" class="form-control input-form-height"
                            (change)="onChangeRegimen()">
                            <optgroup class="label-users-field">
                              <option style="display:none;"></option>
                              <option *ngFor="let regimen of regimenList" [value]="regimen.id"
                                [attr.data-value]="regimen.value" [attr.data-id]="regimen.id">
                                {{regimen.value}}
                              </option>
                            </optgroup>
                          </select>
                        </div>
                      </div>
                      <div class="row" style="margin-top: -20px; margin-bottom: -15px;">
                        <div class="col-lg-1" style="padding-right: 0%;">
                          <input type="checkbox" name="" style="width:15px;" id="accept_tyc"
                            (change)="acceptedTyC = !acceptedTyC">
                        </div>
                        <div class="col-lg-11" style="padding-left: 0%;">
                          <label class="acept-terms-and-conditions">
                            Acepto los
                            <a href="assets/TERMINOS Y CONDICIONES CONTALINK 08_23.pdf" target="_blank"
                              rel="noopener noreferrer" (change)="acceptedTyC = !acceptedTyC">
                              términos y condiciones
                            </a>
                          </label>
                        </div>
                      </div>
                      <div class="row" style="margin-top: 0px; margin-bottom: -15px;">
                        <div class="col-lg-1" style="padding-right: 0%;">
                          <input type="checkbox" name="" style="width:15px;" id="acceptedEmailPromotional"
                            (click)="changeAcceptedPromotionalEmail()" checked>
                        </div>
                        <div class="col-lg-11" style="padding-left: 0%;">
                          <label class="acept-terms-and-conditions">
                            Acepto recibir correos electrónicos de comunicados y promociones.
                          </label>
                        </div>
                      </div>
                      <br>
                      <div class="container-fluid row" style="margin-top: -10px;">
                        <div style="width:100%;" id="stepperNextStepTwo">
                          <!--<button mat-button matStepperNext (click)="sendData();"-->
                          <button mat-button (click)="sendData(stepper);" id="" (mousedown)="DownBtn('two')"
                            [disabled]="!acceptedTyC" style="width:100%;
                            color: white;
                            font-size: 14px;
                            border-radius: 20px;
                            height: 38px;
                            font-family: Lexend;"
                            [ngClass]="{ 'active-button': acceptedTyC, 'inactive-button': !acceptedTyC }">
                            Crear cuenta
                          </button>
                          <!-- background-color: #050029; -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br><br>
      </div>
    </form>
    <!-- S  E   G   U   N   D   O     P   A   S   O     F   I   N   -->
  </mat-step>
  <!-- FIN SEGUNDO PASO -->

  <mat-step completed="stepPMethodCompleted">
    <ng-template matStepLabel>Método de Pago</ng-template>
    <div class="users-form-bckgd" style="margin-top: 2em;">
      <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-6 col-sm-offset-3">
        <p class="users-bg-img-text"></p>
        <div class="users-form form-inline" role="form">
          <mat-card>
            <mat-card-title>Método de Pago</mat-card-title>
            <!-- Esto viajará al paso del m pago -->
            <mat-card-subtitle>
              Seleccione su método de pago.
            </mat-card-subtitle><br>
            <!-- LISTA DE MÉTODOS DE PAGO -->
            <mat-card *ngIf="!isMobile" class="mat-card-paymethod" id="card_payment_tile"
              (click)="change_payment_method('card')">
              <mat-grid-list cols="7" rowHeight="2:0.5">
                <mat-grid-tile colspan="2" rowspan="2">
                  <!--<img src="https://fit78online.com/images/tarjetasfit.png" width="80%">-->
                  <img src="https://contalink-reports.s3.amazonaws.com/PROD/LOGOS/CONTALINK/visa.png" width="80%">
                </mat-grid-tile>
                <mat-grid-tile colspan="4">
                  <p style="text-align:left;width:100%;">Tarjeta de Crédito o débito</p>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="2">
                  <input class="payment_method_check" type="checkbox" name="" style="width:15px;"
                    id="card_payment_method">
                </mat-grid-tile>
                <mat-grid-tile colspan="4">
                  <p style="text-align:left;width:100%;">Acceso inmediatamente después de realizar su pago.</p>
                </mat-grid-tile>
              </mat-grid-list>
            </mat-card><br>
            <mat-card *ngIf="!isMobile" class="mat-card-paymethod" id="spei_oxxo_payment_title"
              (click)="change_payment_method('spei_oxxo')">
              <mat-grid-list cols="7" rowHeight="2:0.5">
                <mat-grid-tile colspan="2" rowspan="2">
                  <img src="https://contalink-reports.s3.amazonaws.com/PROD/LOGOS/CONTALINK/spei_oxxo.png" width="88%">
                </mat-grid-tile>
                <mat-grid-tile colspan="4">
                  <p style="text-align:left;width:100%;">Transferencia Bancaria (CLABE)</p>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="2">
                  <input class="payment_method_check" type="checkbox" name="" style="width:15px;"
                    id="spei_oxxo_payment_method">
                </mat-grid-tile>
                <mat-grid-tile colspan="4">
                  <p style="text-align:left;width:100%;font-size:12px;">Enviaremos a su correo un documento con
                    información para pago.
                  </p>
                </mat-grid-tile>
              </mat-grid-list>
            </mat-card><br>
            <br>
            <!-- LISTA DE MÉTODOS DE PAGO MóVIL-->
            <mat-card *ngIf="isMobile" class="mat-card-paymethod" id="card_payment_tile"
              (click)="change_payment_method('card')">
              <mat-grid-list cols="7" rowHeight="2:1.5">
                <mat-grid-tile colspan="2" rowspan="2">
                  <img src="https://contalink-reports.s3.amazonaws.com/PROD/LOGOS/CONTALINK/visa.png" width="80%">
                </mat-grid-tile>
                <mat-grid-tile colspan="4">
                  <p style="text-align:left;width:100%;"><b>Tarjeta de Crédito</b></p>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="2">
                  <input class="payment_method_check" type="checkbox" name="" style="width:15px;"
                    id="card_payment_method">
                </mat-grid-tile>
                <mat-grid-tile colspan="4">
                  <p style="text-align:left;width:100%;">Acceso inmediato.</p>
                </mat-grid-tile>
              </mat-grid-list>
            </mat-card><br>
            <mat-card *ngIf="isMobile" class="mat-card-paymethod" id="spei_oxxo_payment_title"
              (click)="change_payment_method('spei_oxxo')">
              <mat-grid-list cols="7" rowHeight="2:1.5">
                <mat-grid-tile colspan="2" rowspan="2">
                  <img src="https://contalink-reports.s3.amazonaws.com/PROD/LOGOS/CONTALINK/spei_oxxo.png" width="80%">
                </mat-grid-tile>
                <mat-grid-tile colspan="4">
                  <p style="text-align:left;width:100%;">Transferencia Bancaria (CLABE)</p>
                </mat-grid-tile>
                <mat-grid-tile colspan="1" rowspan="2">
                  <input class="payment_method_check" type="checkbox" name="" style="width:15px;"
                    id="spei_oxxo_payment_method">
                </mat-grid-tile>
                <mat-grid-tile colspan="4">
                  <p style="text-align:left;width:100%;">Enviaremos a su correo un documento con información para pago.
                  </p>
                </mat-grid-tile>
              </mat-grid-list>
            </mat-card><br>
            <!-- BOTÓN SIGUIENTE -->
            <mat-grid-list *ngIf="!isMobile" cols="4" rowHeight="2:0.5">
              <mat-grid-tile colspan="1">
                <!--<button style="width:100%;background-color:#D7E2EA;" 
                  mat-button matStepperPrevious>Atrás</button>-->
              </mat-grid-tile>
              <mat-grid-tile colspan="2">
              </mat-grid-tile>
              <mat-grid-tile colspan="1">
                <div style="width:100%;" id="stepperNextStepPayment" [hidden]="paymentMethod == null">
                  <button mat-button (click)="goPayment(stepper);" id="btn_next_three" (mousedown)="DownBtn('three')"
                    (mouseup)="UpBtn('three')" style="width:100%;background-color:#D7E2EA;">Siguiente</button>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
            <!-- BOTÓN SIGUIENTE MÓVIL-->
            <mat-grid-list *ngIf="isMobile" cols="4" rowHeight="2:1">
              <mat-grid-tile colspan="4">
                <div style="width:100%;" id="stepperNextStepPayment" [hidden]="paymentMethod == null">
                  <button mat-button (click)="goPayment(stepper);" id="btn_next_three" (mousedown)="DownBtn('three')"
                    (mouseup)="UpBtn('three')"
                    style="width:100%;background-color:#D7E2EA;height:3em;">Siguiente</button>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-step>
  <!-- T  E   R   C   E   R     P   A   S   O     F   I   N   --> -

  <mat-step completed="stepThreeCompleted">
    <ng-template matStepLabel>Información de Pago</ng-template>
    <div class="users-form-bckgd" style="margin-top: 2em; margin-bottom: 12em;">
      <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-6 col-sm-offset-3">
        <p class="users-bg-img-text"></p>
        <div class="users-form form-inline" role="form">
          <mat-card>
            <h2><b>Su Pago</b></h2>
            <p>Total: {{ planInformation?.total_sale || 0 | number:'1.2-2' }} MXN</p>
            <form id="payment-form">
              <form id="payment-form">
                <div id="payment-element"></div>
              </form>
            </form>

            <mat-grid-list cols="4" rowHeight="2:1">
              <mat-grid-tile colspan="4">
                <div style="width:25%;">
                  <button mat-button style="width:100%;background-color:#D7E2EA;height:3em;"
                    (click)="goStepThree(stepper);">
                    Cambiar Método de Pago
                  </button>
                </div>
                <div style="width:55%;">
                </div>
                <div style="width:25%;" id="stepperNextStepPayment">
                  <!-- <button mat-button (click)="goToStep(4, stepper);"  -->
                  <button mat-button (click)="goToStep(4, stepper);" id="btn_next_three" (mouseup)="UpBtn('three')"
                    style="width:100%;background-color:#D7E2EA;height:3em;" [disabled]="!cardInputs">Confirmar
                    tarjeta</button>
                </div>
              </mat-grid-tile>

            </mat-grid-list>
            <script src="https://js.stripe.com/v3/"></script>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-step>

  <mat-step completed="stepFourCompleted">
    <ng-template matStepLabel>Pago</ng-template>
    <div class="users-form-bckgd" style="margin-top: 2em; margin-top: 7em; margin-bottom: 20em;">
      <div class="col-lg-8 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-6 col-sm-offset-3">
        <div class="users-form form-inline text-center" role="form">
          <mat-card>
            <mat-card-title>Información de Pago</mat-card-title>
            <mat-card-subtitle *ngIf="(this.planInformation && this.planInformation.total_sale || '')">
              Su Pago por: {{ this.planInformation.total_sale || 0 | number:'1.2-2' }} MXN
            </mat-card-subtitle>
            <mat-card-subtitle *ngIf="(this.planInformation && this.planInformation.plan_short_name || '')">
              Plan: {{this.planInformation.plan_short_name || ''}}
            </mat-card-subtitle>
            <div id="error-message"></div>

            <mat-grid-list cols="4" rowHeight="2:1">
              <mat-grid-tile colspan="4">
                <div style="width:25%;">
                  <button mat-button style="width:100%;background-color:#D7E2EA;height:3em; margin-top: 1.5em;"
                    (click)="goToStep(3, stepper);">
                    Cambiar tarjeta
                  </button>
                </div>
                <div style="width:55%;">
                </div>
                <div style="width:25%;" id="stepperNextStepPayment">
                  <button (click)="sendPay(stepper)"
                    style="width:100%;background-color:#70B2E5;padding-top:0.6em;padding-bottom:0.6em;font-weight:bold;margin-top:2em;color:white;border:1px solid #70B2E5;"
                    id="submit" type="button">Pagar
                  </button>
                </div>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-card>
        </div>
      </div>
    </div>
  </mat-step>


  <mat-step completed="stepFiveCompleted">
    <ng-template matStepLabel>¡Listo!</ng-template>
    <div class="users-form-bckgd" style="margin-bottom: 12em; margin-top: 2em;">
      <div class="main-container min-h-screen flex flex-col items-center justify-center relative pt-50 pb-8">
        <h1 class="title text-contalink-primary text-center text-4xl md:text-6xl lg:text-7xl font-bold animate-fade">
          {{ pageTitle ? pageTitle : "¡Gracias por contactarnos!" }}
        </h1>
        <h2 *ngIf="pageSubtitle"
          class="title text-contalink-secondary text-center text-3xl md:text-5xl font-normal animate-fade">
          {{ pageSubtitle }}</h2>

        <div *ngIf="showExitoso" class="animate-fade">
          <p class="normal-text text-contalink-black text-xl text-center">
            Te invitamos a ver nuestro contenido de
            <a href="https://tutoriales.contalink.com/es/collections/6941920-primeros-pasos" target="_blank"
              rel="noopener noreferrer" class="link-hover font-medium">Introducción y primeros pasos</a>, con ello
            podrás conocer como funciona Contalink.
          </p>
          <p class="normal-text text-contalink-black text-xl text-center">
            Para dudas y soporte inicial puedes contactar a:
          </p>
        </div>

        <div *ngIf="showError" class="animate-fade">
          <p class="normal-text text-contalink-black text-xl">
            Tu pago ha sido procesado pero hemos tenido un problema para activar tu
            cuenta. Por favor comunícate con nuestro equipo a través del correo
            <a href="mailto:info@contalink.com" class="link-hover font-semibold">info@contalink.com</a>
            o por WhatsApp al
            <a href="https://api.whatsapp.com/send/?phone=528119107569&text&type=phone_number&app_absent=0"
              target="_blank" rel="noopener noreferrer" class="link-hover font-semibold">81 19 10 75 69</a>
            <a href="https://api.whatsapp.com/send/?phone=528119107569&text&type=phone_number&app_absent=0"
              target="_blank" rel="noopener noreferrer" class="link-hover font-semibold">81 19 10 75 69</a>
          </p>
        </div>

        <div *ngIf="showPendiente" class="animate-fade mb-16 text-center">
          <p class="normal-text text-contalink-black text-xl">
            En menos de 24 horas recibirás en tu correo la información para pagar
            mediante transferencia bancaria.
          </p>
          <p class="normal-text text-contalink-black text-xl">
            Una vez efectuado el pago, se realizará la activación de tu cuenta. Si tienes cualquier duda por favor
            contactáctanos en:
          </p>
          <p class="noraml-text text-contalink-black text-xl">
            <a href="mailto:info@contalink.com" class="link-hover font-semibold">info@contalink.com</a>
            o por WhatsApp al
            <a href="https://api.whatsapp.com/send/?phone=528119107569&text&type=phone_number&app_absent=0"
              class="link-hover font-semibold">811 910 7569</a>
          </p>
        </div>

        <div *ngIf="showLite" class="animate-fade mb-16">
          <div class="flex items-center justify-center">
            <a href="https://app.contalink.com" target="_blank" rel="noopener noreferrer"
              class="btn btn-success py-4 px-12 text-white">Iniciar sesión</a>
          </div>
        </div>

        <div style="width:100%;text-align:center; margin-top: 5em;">
          <button mat-button style="width:50%;background-color:#D7E2EA;" (click)="goStepThree(stepper);">
            Cambiar Método de Pago
          </button>
        </div>
      </div>
    </div>
  </mat-step>
</mat-horizontal-stepper>
<div class="row mt-10">
  <div class="col-lg-12 col-md-12 col-sm-12 text-center">
    <!--<a routerLink="/aviso-de-privacidad">Aviso de privacidad</a>-->
  </div>
</div>